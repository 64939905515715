export const cities = [
  'milan',
  'turin',
  'brescia',
  'venice',
  'genoa',
  'bologna',
  'florence',
  'rome',
  'naples',
  'catania',
  'padua',
];

export const properties = [
  {
    key: 'studio',
    value: 'Studio',
  },
  {
    key: 'twoRoomsApartment',
    value: '2 Rooms Apartment',
  },
  {
    key: 'threeRoomsApartment',
    value: '3 Rooms Apartment',
  },
  {
    key: 'fourRoomsApartment',
    value: '4+ Rooms Apartment',
  },
  {
    key: 'entireProperty',
    value: 'Entire Property',
  },
  {
    key: 'privateRoom',
    value: 'Private Room',
  },
  {
    key: 'sharedRoom',
    value: 'Shared Room',
  },
];

export const referralContacts = {
  email: 'referral@zappyrent.com',
  phone: '+39 351 8942541',
};

export const getListingUri = (
  language: string,
  city: string,
  type?: string,
): string => {
  const firstChunk = language === 'it' ? `affitti-${city}` : `renting-${city}`;
  const secondChunk = type ? `/${type}` : '';
  const chunks = `${language}/${firstChunk}${secondChunk}`;
  return `${process.env.GATSBY_SITE_URL}/${chunks}`;
};

export const vatNumber = '05477800873';
