import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';

import { theme } from '@zappyrent/hundop';
import { normalize, rem } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  ${normalize()}

  html {
    color: ${theme.colors.mainAlternate};
    font-family: ${theme.fonts.body};
    font-size: 1em;
    font-weight: 400;
    line-height: 140%;
    background-color: ${theme.colors.main};
    scroll-behavior: smooth;
  }

  body {
    overflow-x: hidden;

    &.prevent-scroll {
      height: 100vh;
      overflow: hidden;
    }
  }

  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: ${theme.fonts.body};
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.l}) {
    padding-bottom: ${rem('54px')}; // BottomBar
  }
`;
