import { useEffect, useState } from 'react';

const useUtmQueryString = (defaultUtmMedium: string): string => {
  const [urlParams, setUrlParams] = useState<URLSearchParams>();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search;
      if (queryString) {
        setUrlParams(new URLSearchParams(queryString));
      }
    }
  }, []);

  const utmSource = urlParams?.get('utm_source') ?? '';
  const utmMedium = urlParams?.get('utm_medium') ?? defaultUtmMedium;
  const utmCampaign = urlParams?.get('utm_campaign') ?? '';

  return `utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
};

export default useUtmQueryString;
